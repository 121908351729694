
/**
 * Export `zIndex`.
 */

export const zIndex = {
  banner: 100,
  navbar: 10,
  recaptcha: 1100
};
