
/**
 * Export `locations`.
 */

export const locations = {
  inPerson: 'in_person',
  remote: 'remote'
};

/**
 * Export `unitsOfAccount`.
 */

export const unitsOfAccount = {
  class: 'class',
  day: 'day',
  event: 'event',
  hour: 'hour',
  job: 'job',
  monthly: 'monthly',
  other: 'other',
  session: 'session',
  weekly: 'weekly'
};

/**
 * Export `productTypes`.
 */

export const productTypes = {
  digital: 'digital',
  fund_raise: 'fund_raise',
  physical: 'physical',
  service: 'service'
};
