
/**
 * Module dependencies.
 */

import * as linkify from 'linkifyjs';
import { BackgroundImage } from 'react-components/media';
import { BackgroundPreview } from 'client/components/home/join-section/mobile-app-preview/background-preview';
import { Button, Heading, ModalPortal, typography, useImageSources } from '@slyk/design-system';
import { Container } from 'client/components/home/container';
import { DesktopAction } from './desktop-action';
import { Fill } from 'react-components/layout';
import { GetAppModal } from 'client/components/home/get-app-modal';
import { Product } from 'types/product';
import { ProductsPreview } from 'client/components/home/join-section/mobile-app-preview/products-preview';
import { color, media } from 'react-components/styles';
import { ifProp, prop } from 'styled-tools';
import { isNil } from 'lodash';
import { useBreakpoint } from '@slyk/breakpoint';
import { useIntersection } from 'client/hooks/use-intersection';
import { usePayspace } from '@slyk/payspace-context';
import { useTranslate } from '@slyk/i18n';
import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import backgroundDesktop from 'client/assets/images/home/background.webp';
import backgroundMobile from 'client/assets/images/home/background-mobile.webp';
import backgroundTablet from 'client/assets/images/home/background-tablet.webp';
import iphoneImage from 'client/assets/images/mobile-app-preview/iphone.webp';
import remarkGfm from 'remark-gfm';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  canDownload: boolean;
  code: string | null | undefined;
  downloadAppUrl: string;
  isHeader: boolean;
  navbarHeight: number;
  onGetApp: () => void;
  products: Array<Product & {
    displayPrice: string;
  }>;
};

/**
 * `JoinWrapper` styled component.
 */

const JoinWrapper = styled.div<{ paddingTop: number }>`
  --navbar-height: ${prop('paddingTop', 0)}px;
  
  padding-top: var(--navbar-height);
  position: relative;
`;

/**
 * `DesktopImage` styled component.
 */

const DesktopImage = styled.img`
  display: none;

  ${media.min('lg')`
    display: block;
    height: 100%;
    margin-left: -82%;
    position: absolute;
    top: -22px;
  `}

  ${media.min('xxl')`
    margin-left: -66%;
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div<{ isHeader: boolean }>`
  display: grid;
  gap: 40px 16px;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 80px;
  padding-top: 80px;

  ${ifProp('isHeader', css`
    padding-top: 32px;
  `)}

  ${media.min('xxs')`
    margin-bottom: 96px;

    ${ifProp('isHeader', css`
      padding-top: 40px;
    `)}
  `}

  ${media.min('xs')`
    gap: 120px 32px;
    grid-template-columns: repeat(8, 1fr);
    margin-bottom: 160px;
    padding-top: 120px;

    ${ifProp('isHeader', css`
      padding-top: 80px;
    `)}
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 112px;
    padding-top: 96px;
    row-gap: 0;

    ${ifProp('isHeader', css`
      padding-top: 80px;
    `)}
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-column: 1 / -1;
  position: relative;

  ${media.min('xs')`
    grid-column: 2 / -2;
  `}

  ${media.min('lg')`
    align-self: center;
    grid-column: 7 / -1;
    grid-row: 1;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Heading)`
  font-weight: 600;
  margin-bottom: ${ifProp('hasGreeting', 16, 48)}px;
`;

/**
 * `Greeting` styled component.
 */

const Greeting = styled(ReactMarkdown)`
  ${typography.styles.textNormal}

  color: ${color('secondary')};
  margin-bottom: 40px;
  position: relative;
  word-break: break-word;

  ${media.min('lg')`
    margin-bottom: 56px;
  `}

  p {
    margin: 0;
  }

  ul,
  ol {
    list-style: revert;
    margin: revert;
    padding: revert;
    text-align: start;
  }
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  ${media.min('xs')`
    width: max-content;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  display: flex;
  grid-column: 1 / -1;
  margin: 0 -16px;
  position: relative;

  ${media.min('ms')`
    margin: 0 -24px;
  `}

  ${media.min('lg')`
    grid-column: 2 / span 4;
    grid-row: 1;
  `}
`;

/**
 * `MobileBackgroundImage` styled component.
 */

const MobileBackgroundImage = styled.img`
  height: 100%;
  position: absolute;
  width: 100%;

  ${media.min('lg')`
    display: none;
  `}
`;

/**
 * `FrameWrapper` styled component.
 */

const FrameWrapper = styled.div<{ isIntersecting: boolean }>`
  height: 100%;
  margin: 0 auto;
  max-height: 680px;
  max-width: 256px;
  position: relative;
  transition: transform 1s ease-in-out;

  ${ifProp('isIntersecting', css`
    transform: scale(1);
  `, css`
    transform: scale(0.95);
  `)}

  ${media.min('xs')`
    transform: none;
  `}

  ${media.min('ms')`
    max-width: 324px;
  `}

  ${media.min('lg')`
    margin-left: 0;
  `}
`;

/**
 * `IPhoneImage` styled component.
 */

const IPhoneImage = styled.img`
  display: block;
  position: relative;
  z-index: 1;
`;

/**
 * Background style.
 */

const backgroundStyle = css`
  backdrop-filter: opacity(0);
  bottom: 14%;
  display: none;
  filter: blur(45px);
  height: 100%;
  left: -15%;
  max-height: 284px;
  max-width: 138px;
  opacity: 0.5;
  right: initial;
  top: initial;
  transform: rotate(-18.35deg);
  width: 100%;

  ${media.min('xs')`
    display: block;
  `}

  ${media.min('ms')`
    bottom: 10%;
    left: -25%;
    max-height: 556px;
    max-width: 271px;
  `}
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  ${backgroundStyle}
`;

/**
 * `DefaultBackground` styled component.
 */

const DefaultBackground = styled(Fill)`
  ${backgroundStyle}

  background: linear-gradient(180deg, rgba(11, 102, 185, 0.8) 0%, rgba(12, 63, 134, 0.32) 0.01%, rgba(22, 80, 168, 0.648) 100%);
`;

/**
 * Export `JoinSection` component.
 */

export function JoinSection(props: Props): JSX.Element {
  const { canDownload, code, downloadAppUrl, isHeader, navbarHeight, onGetApp, products } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const { background, greeting, logo: payspaceLogo, name, title } = usePayspace();
  const { isIntersecting, ref } = useIntersection();
  const { translate } = useTranslate();
  const payspaceTitle = title || name;
  const isVideo = background?.type === 'video';
  const isMobile = useBreakpoint('xs', 'max');
  const hasBackgroundVideo = isVideo && background?.status === 'completed';
  const backgroundUrl = useImageSources(isVideo ? background?.shareUrl : background?.url, '1024x');
  const formattedGreeting = useMemo(() => {
    if (!greeting) {
      return greeting;
    }

    const linkableList = linkify.find(greeting) ?? [];
    const urlLinks = linkableList.filter(({ type }) => type === 'url');

    return urlLinks.reduce((result, { value }) => {
      const formattedValue = value.replace('\\_', '_');

      return result.replace(value, formattedValue);
    }, greeting);
  }, [greeting]);

  return (
    <>
      <JoinWrapper
        as={isHeader ? 'header' : 'section'}
        paddingTop={isHeader ? navbarHeight : 0}
      >
        <Container>
          <DesktopImage
            alt={'Background image'}
            aria-hidden
            src={backgroundDesktop}
          />

          <ContentWrapper isHeader={isHeader}>
            <TextWrapper>
              {payspaceTitle && (
                <Title
                  as={isHeader ? 'h1' : 'h2'}
                  hasGreeting={Boolean(greeting)}
                  level={isMobile || isHeader ? 'h2' : 'h3'}
                >
                  {payspaceTitle}
                </Title>
              )}

              {formattedGreeting && (
                <Greeting remarkPlugins={[remarkGfm]}>
                  {formattedGreeting}
                </Greeting>
              )}

              {!canDownload && (
                <DesktopAction
                  code={code}
                  openModal={() => setModalOpen(true)}
                />
              )}

              {canDownload && downloadAppUrl && (
                <StyledButton
                  href={downloadAppUrl}
                  rel={'noopener'}
                  responsive={false}
                  size={'large'}
                  target={'_blank'}
                >
                  {translate('header.appDownload')}
                </StyledButton>
              )}
            </TextWrapper>

            <ImageWrapper>
              <MobileBackgroundImage
                alt={'Background image'}
                aria-hidden
                src={isMobile ? backgroundMobile : backgroundTablet}
              />

              <FrameWrapper
                isIntersecting={isIntersecting}
                ref={ref}
              >
                <IPhoneImage
                  alt={'IPhone'}
                  height={'100%'}
                  src={iphoneImage}
                  width={'100%'}
                />

                {!isNil(background) ? <StyledBackgroundImage {...backgroundUrl} /> : <DefaultBackground />}

                {hasBackgroundVideo ? (
                  <BackgroundPreview
                    background={background}
                    description={title}
                    logoUrl={payspaceLogo?.url}
                    name={name}
                    onGetApp={onGetApp}
                  />
                ) : (
                  <ProductsPreview
                    description={title}
                    logoUrl={payspaceLogo?.url}
                    name={name}
                    products={products}
                  />
                )}
              </FrameWrapper>
            </ImageWrapper>
          </ContentWrapper>
        </Container>
      </JoinWrapper>

      {!canDownload && (
        <ModalPortal isOpen={isModalOpen}>
          <GetAppModal
            code={code}
            downloadAppUrl={downloadAppUrl}
            isVisible={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            showOnlyQrCode
          />
        </ModalPortal>
      )}
    </>
  );
}
