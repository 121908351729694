
/**
 * Export `satCode`.
 */

export const satCode = 'sat';

/**
 * Export `satSymbol`.
 */

export const satSymbol = 'sats';

/**
 * Export `assetTypes`.
 */

export const assetTypes = {
  crypto: 'crypto',
  custom: 'custom',
  fiat: 'fiat'
};
