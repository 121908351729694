
/**
 * Module dependencies.
 */

import { BackgroundImage } from 'react-components/media';
import { ConditionalRoute } from '@slyk/routing';
import { ConfirmEmail } from 'client/containers/confirm-email/confirm-email';
import { Helmet } from 'react-helmet';
import { Home } from 'client/containers/home/home';
import { PageLayout } from 'client/components/layouts/page-layout';
import {
  PayspaceNotActive
} from 'client/containers/payspace-not-active/payspace-not-active';

import { Redirect, Route, Switch } from 'react-router-dom';
import { ResetPassword } from 'client/containers/reset-password/reset-password';
import {
  ResetPasswordSuccess
} from 'client/containers/reset-password-success/reset-password-success';

import { getImageSources } from 'client/core/utils/image';
import { payspaceStatuses } from '@slyk/types';
import { usePayspace } from '@slyk/payspace-context';
import { useTranslate } from '@slyk/i18n';
import React, { useEffect, useMemo } from 'react';
import config from 'config';
import dashboardRoutes from '@slyk/config/dashboard-routes';
import moment from 'moment';
import payspaceRoutes from '@slyk/config/payspace-routes';
import styled from 'styled-components';

/**
 * Slyk link.
 */

const slykLink = config.get<string>('externalLinks.slykWebsite');

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: contain;
`;

/**
 * Export `App` container.
 */

export function App(): JSX.Element {
  const { i18n, translate } = useTranslate();
  const payspace = usePayspace();
  const domainIsReady = payspace.status === payspaceStatuses.ready;
  const domainIsUnconfigured = payspace.status === payspaceStatuses.unconfigured;
  const htmlAttributes = useMemo(() => ({
    lang: i18n.language
  }), [i18n.language]);

  const title = translate('metatags.payspace.title', {
    payspaceName: payspace.name
  });

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <Helmet
        defaultTitle={title}
        htmlAttributes={htmlAttributes}
        titleTemplate={`%s - ${title}`}
      />

      <Switch>
        <ConditionalRoute
          exact
          path={payspaceRoutes.home}
          rules={[{
            external: true,
            redirectTo: dashboardRoutes.signIn,
            rule: domainIsUnconfigured
          }, {
            redirectTo: payspaceRoutes.payspaceNotActive,
            rule: !domainIsReady && !domainIsUnconfigured
          }]}
        >
          <Home />
        </ConditionalRoute>

        <ConditionalRoute
          exact
          path={payspaceRoutes.acceptInvitation}
          rules={[{
            redirectTo: payspaceRoutes.payspaceNotActive,
            rule: !domainIsReady
          }]}
        >
          <Redirect to={payspaceRoutes.home} />
        </ConditionalRoute>

        <Route
          path={[
            payspaceRoutes.resetPassword,
            payspaceRoutes.resetPasswordSuccess,
            payspaceRoutes.confirmEmail,
            payspaceRoutes.payspaceNotActive
          ]}
        >
          <PageLayout
            header={(
              <Switch>
                <Route
                  exact
                  path={payspaceRoutes.confirmEmail}
                >
                  <BackgroundImage
                    {...getImageSources('background', 'webp')}
                  />

                  <StyledBackgroundImage
                    {...getImageSources('confirm-email', 'webp')}
                  />
                </Route>

                <Route
                  exact
                  path={payspaceRoutes.payspaceNotActive}
                >
                  <BackgroundImage
                    {...getImageSources('background', 'webp')}
                  />

                  <StyledBackgroundImage
                    {...getImageSources('payspace-not-active', 'webp')}
                  />
                </Route>

                <Route path={'*'}>
                  <BackgroundImage
                    {...getImageSources('auth-image-fallback', 'webp')}
                  />
                </Route>
              </Switch>
            )}
            logoLink={slykLink}
          >
            <Switch>
              <ConditionalRoute
                exact
                path={payspaceRoutes.resetPassword}
                rules={[{
                  redirectTo: payspaceRoutes.payspaceNotActive,
                  rule: !domainIsReady
                }]}
              >
                <ResetPassword />
              </ConditionalRoute>

              <ConditionalRoute
                exact
                path={payspaceRoutes.resetPasswordSuccess}
                rules={[{
                  redirectTo: payspaceRoutes.payspaceNotActive,
                  rule: !domainIsReady
                }]}
              >
                <ResetPasswordSuccess />
              </ConditionalRoute>

              <ConditionalRoute
                exact
                path={payspaceRoutes.confirmEmail}
                rules={[{
                  redirectTo: payspaceRoutes.payspaceNotActive,
                  rule: !domainIsReady
                }]}
              >
                <ConfirmEmail />
              </ConditionalRoute>

              <ConditionalRoute
                exact
                path={payspaceRoutes.payspaceNotActive}
                rules={[{
                  redirectTo: payspaceRoutes.home,
                  rule: domainIsReady
                }]}
              >
                <PayspaceNotActive />
              </ConditionalRoute>
            </Switch>
          </PageLayout>
        </Route>

        <Redirect to={payspaceRoutes.home} />
      </Switch>
    </>
  );
}
