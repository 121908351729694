
/**
 * Module dependencies.
 */

import { Link, Text } from '@slyk/design-system';
import { PhoneNumberInputForm } from 'client/components/home/phone-number-input-form';
import { Trans } from 'react-i18next';
import { color, media } from 'react-components/styles';
import { useTranslate } from '@slyk/i18n';
import React from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  code: string | null | undefined;
  openModal: () => void;
};

/**
 * `CardWrapper` styled component.
 */

const CardWrapper = styled.div`
  max-width: 478px;
  width: 100%;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ size: 'large' })`
  color: ${color('primary')};
  font-weight: 700;
  margin-bottom: 8px;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ size: 'normal' })`
  color: ${color('secondary')};
  margin-bottom: 20px;

  ${media.min('ms')`
    margin-bottom: 16px;
  `}
`;

/**
 * `QrCodeDescription` styled component.
 */

const QrCodeDescription = styled(Text).attrs({ size: 'normal' })`
  color: ${color('secondary')};
  padding-top: 4px;

  ${media.min('ms')`
    padding-top: 0;
  `}
`;

/**
 * `QrCodeLink` styled component.
 */

const QrCodeLink = styled(Link)`
  font-weight: 700;
`;

/**
 * Export `DesktopAction` component.
 */

export function DesktopAction({ code, openModal }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <CardWrapper>
      <Title>
        {translate('header.title')}
      </Title>

      <Description>
        <Trans
          i18nKey={'header.description'}
          ns={'payspace'}
        />
      </Description>

      <PhoneNumberInputForm code={code} />

      <QrCodeDescription>
        <Trans
          components={[(
            <QrCodeLink
              key={0}
              onClick={openModal}
            />
          )]}
          i18nKey={'header.qrCodeDescription'}
          ns={'payspace'}
        />
      </QrCodeDescription>
    </CardWrapper>
  );
}
