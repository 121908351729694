
/**
 * Module dependencies.
 */

import { createSlykTheme } from '@slyk/design-system';
import { dimensions } from './dimensions';
import { zIndex } from './z-index';

/**
 * Export `theme`.
 */

export const theme = createSlykTheme({
  dimensions,
  grid: {
    containers: { lg: 1440 }
  },
  zIndex
});
