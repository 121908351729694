
/**
 * Module dependencies.
 */

import { Button, Heading, Text } from '@slyk/design-system';
import { Col, Container, Row } from 'react-components/layout';
import { color } from 'react-components/styles';
import { isProduction } from '@slyk/core';
import { useTranslate } from '@slyk/i18n';
import React from 'react';
import sentryClient from '@slyk/sentry-client';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  error: Error | null | undefined;
  errorInfo: {
    componentStack: string;
  } | null | undefined;
  sentryEventId: string | null | undefined;
};

/**
 * `Details` styled component.
 */

const Details = styled.details`
  white-space: pre-wrap;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

/**
 * Export `DefaultErrorMessage` component.
 */

export function DefaultErrorMessage(props: Props): JSX.Element {
  const { error, errorInfo, sentryEventId } = props;
  const { translate } = useTranslate();

  return (
    <Container>
      <Row>
        <Col>
          <Heading
            as={'h1'}
            color={color('primary')}
            fontWeight={600}
            marginBottom={'16px'}
            paddingTop={'32px'}
          >
            {translate('defaultErrorPage.title')}
          </Heading>

          {!isProduction() && (
            <>
              <Text marginBottom={'8px'}>
                {error && error.toString()}
              </Text>

              <Details>
                <summary>
                  {translate('defaultErrorPage.details.errorStack')}
                </summary>

                {error?.stack}
              </Details>

              <Details>
                <summary>
                  {translate('defaultErrorPage.details.errorInfo')}
                </summary>

                {errorInfo?.componentStack}
              </Details>
            </>
          )}

          {sentryClient.isReportDialogToShow() && (
            <Button
              onClick={() => sentryClient.showReportDialog({ sentryEventId })}
              size={'medium'}
            >
              {translate('defaultErrorPage.buttonLabel')}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}
