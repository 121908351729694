
/**
 * Module dependencies.
 */

import { interpolationConfig } from '@slyk/i18n';
import { isProduction } from '@slyk/core';
import XHR from 'i18next-http-backend';
import config from 'config';
import i18next from 'i18next';
import urlJoin from 'url-join';

/**
 * Base path.
 */

const basePath = config.get<string>('basePath');

/**
 * Locales path.
 */

const localesPath = config.get<string>('localization.route');

/**
 * `i18next` configuration.
 */

i18next
  .use(XHR)
  .init({
    backend: {
      loadPath: urlJoin('/', basePath, localesPath, '/{{lng}}/{{ns}}.json')
    },
    debug: !isProduction(),
    defaultNS: config.get<string>('localization.defaultNamespace'),
    fallbackLng: config.get<string>('localization.fallbackLocale'),
    fallbackNS: config.get<string>('localization.fallbackNamespaces'),
    interpolation: interpolationConfig,
    ns: config.get<string>('localization.namespaces'),
    react: {
      transKeepBasicHtmlNodesFor: ['b', 'br', 'i', 'p', 'strong']
    }
  });

/**
 * Export `i18n`.
 */

export const i18n = i18next;
