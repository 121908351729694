
/**
 * Module dependencies.
 */

import { Box, Fill } from 'react-components/layout';
import {
  Button,
  Text,
  Tooltip,
  UserAvatar,
  typography
} from '@slyk/design-system';

import { Icon } from 'react-components/media';
import { color, media } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import { isEmpty, throttle, toLower } from 'lodash';
import { payspaceSignupModes } from '@slyk/types';
import { useBreakpoint } from '@slyk/breakpoint';
import { usePayspace } from '@slyk/payspace-context';
import { useTranslate } from '@slyk/i18n';
import React, { useEffect, useState } from 'react';
import config from 'config';
import privateIcon from 'client/assets/svg/24/private.svg';
import styled, { css } from 'styled-components';

/**
 * App domain.
 */

const appDomain = config.get<string>('app.domain');

/**
 * Badges types.
 */

const badgesTypes = [
  'hypergrowth',
  'launch',
  'slykpay',
  'slykraise',
  'traction'
];

/*
 * `Props` type.
 */

type Props = {
  downloadAppDisabled: boolean;
  onGetApp: () => void;
};

/**
 * Text style.
 */

const textStyle = css`
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.min('sm')`
    max-width: 25ch;
  `}
`;

/**
 * `Nav` styled component.
 */

const Nav = styled.nav<{ isFixed: boolean }>`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr max-content;
  left: 0;
  padding: 16px 16px 0;
  position: absolute;
  right: 0;
  transition: color ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.navbar')};

  ${ifProp('isFixed', css`
    animation: ${theme('keyframes.fadeInDown')} 0.5s ease both;
    padding-bottom: 16px;
    position: fixed;
  `)}

  ${media.min('ms')`
    padding: 32px 24px 0;

    ${ifProp('isFixed', css`
      padding-bottom: 32px;
    `)}
  `}

  ${media.min('lg')`
    padding: 32px 32px 0;

    ${ifProp('isFixed', css`
      padding-bottom: 32px;
    `)}
  `}
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled(Fill)<{ isFixed: boolean }>`
  background-color: ${color('white')};
  opacity: 0.9;
  transform: translateY(-100%);
  transition: transform ${theme('animations.fastTransition')};
  z-index: -1;

  ${ifProp('isFixed', css`
    transform: translateY(0);
  `)}
`;

/**
 * `PayspaceWrapper` styled component.
 */

const PayspaceWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'avatar domain'
    'tags   tags';
  grid-template-columns: max-content 1fr;

  ${media.min('sm')`
    grid-template-areas: 'avatar domain tags download';
    grid-template-columns: repeat(2, max-content) 1fr max-content;
  `}

  ${media.min('ms')`
    margin: 0 auto;
    max-width: 1632px;
    width: 100%;
  `}
`;

/**
 * `StyledUserAvatar` styled component.
 */

const StyledUserAvatar = styled(UserAvatar)`
  ${typography.styles.heading4}

  grid-area: avatar;
`;

/**
 * `Domain` styled component.
 */

const Domain = styled.div`
  grid-area: domain;
  margin-left: 16px;
  padding-top: 8px;

  ${media.min('ms')`
    margin-left: 24px;
  `}
`;

/**
 * `Badges` styled component.
 */

const Badges = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  grid-area: tags;
  height: max-content;
  padding-top: 16px;

  ${media.min('sm')`
    margin-left: 16px;
    margin-right: 8px;
    padding-top: 8px;
  `}
`;

/**
 * `Badge` styled component.
 */

const Badge = styled.div<{ isGreen: boolean }>`
  ${typography.styles.textSmall}

  background-color: ${color('white')};
  border: 1px solid ${ifProp('isGreen', '#D3F1CE', '#D1E5FF')};
  border-radius: 8px;
  color: ${ifProp('isGreen', color('success500'), color('primary'))};
  height: max-content;
  padding: 3px 5px;
`;

/**
 * `BadgeIcon` styled component.
 */

const BadgeIcon = styled.span`
  margin-right: 8px;
`;

/**
 * `Slug` styled component.
 */

const Slug = styled(Text)`
  ${textStyle}

  font-weight: 700;
`;

/**
 * `TooltipContent` styled component.
 */

const TooltipContent = styled(Text).attrs({
  as: 'div',
  size: 'small'
})`
  text-align: center;
`;

/**
 * `PayspaceUrl` styled component.
 */

const PayspaceUrl = styled(Text).attrs({ as: 'div', size: 'small' })`
  ${textStyle}

  color: ${color('gray700')};
`;

/**
 * `LabelForPrivate` styled component.
 */

const LabelForPrivate = styled.div`
  align-items: center;
  background-color: ${color('gray300')};
  border-radius: 8px;
  display: flex;
  padding: 3px 5px;
  width: max-content;
`;

/**
 * Export `Navbar` component.
 */

export function Navbar({ downloadAppDisabled, onGetApp }: Props): JSX.Element {
  const { translate } = useTranslate();
  const [isFixed, setFixed] = useState(false);
  const isMobile = useBreakpoint('sm', 'max');
  const isDesktop = useBreakpoint('ms', 'min');
  const { badges, logo, name, signupMode, slug } = usePayspace();
  const isPrivate = signupMode === payspaceSignupModes.private;

  useEffect(() => {
    const onScroll = throttle(() => {
      setFixed(window.scrollY > 150);
    }, 250);

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Nav isFixed={isFixed}>
      <Underlay isFixed={isFixed} />

      <PayspaceWrapper>
        <StyledUserAvatar
          imageUrl={logo?.url}
          name={name}
          size={isDesktop ? '70px' : '48px'}
        />

        <Domain>
          <Slug>
            {name}
          </Slug>

          <PayspaceUrl>
            {`${slug}.${appDomain}`}
          </PayspaceUrl>
        </Domain>

        {(isPrivate || !isEmpty(badges)) && (
          <Badges>
            {isPrivate && (
              <LabelForPrivate>
                <Icon
                  aria-hidden
                  color={color('gray500')}
                  icon={privateIcon}
                  size={'20px'}
                />

                {!isMobile && (
                  <Text
                    color={color('gray500')}
                    marginLeft={'4px'}
                    size={'small'}
                  >
                    {translate('navbar.privateLabel')}
                  </Text>
                )}
              </LabelForPrivate>
            )}

            {badges.map((item: string, index: number) => {
              const badge = toLower(item);

              if (badgesTypes.includes(badge)) {
                return (
                  <Tooltip
                    content={
                      <TooltipContent>
                        {translate([
                          `navbar.badges.${badge}.description`,
                          'labels.notAvailable'
                        ])}
                      </TooltipContent>
                    }
                    key={index}
                    minWidth={'140px'}
                    placement={'bottom'}
                  >
                    <Badge isGreen={item === 'launch'}>
                      <BadgeIcon>
                        {translate([
                          `navbar.badges.${badge}.icon`,
                          ''
                        ])}
                      </BadgeIcon>

                      {translate([
                        `navbar.badges.${badge}.title`,
                        'labels.notAvailable'
                      ])}
                    </Badge>
                  </Tooltip>
                );
              }
            })}
          </Badges>
        )}

        {!isMobile && (
          <Box gridArea={'download'}>
            <Button
              disabled={downloadAppDisabled}
              onClick={onGetApp}
              size={'large'}
            >
              {translate('navbar.getApp')}
            </Button>
          </Box>
        )}
      </PayspaceWrapper>
    </Nav>
  );
}
