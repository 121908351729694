
/**
 * Module dependencies.
 */

import { ImageSources } from 'types/media';

/**
 * Export `getImageSources`.
 */

export const getImageSources = (
  filename: string,
  extension: string,
  retinaSuffix = '@2x'
): ImageSources => ({
  defaultUrl: require(`client/assets/images/${filename}.${extension}`),
  retinaUrl: require(
    `client/assets/images/${filename}${retinaSuffix}.${extension}`
  )
});
