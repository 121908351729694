
/**
 * Module dependencies.
 */

import { Heading, Link, logo } from '@slyk/design-system';
import { Icon, Image } from 'react-components/media';
import { ImageSources } from 'types/media';
import { color, media } from 'react-components/styles';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  image: ImageSources;
  logoLink: string;
  title: string;
};

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 343px;
  padding: 0 16px 56px;
  width: 100%;

  ${media.min('ms')`
    max-width: 410px;
  `}

  ${media.min('md')`
    max-width: 448px;
    padding: 0 0 72px;
  `}
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  margin: 32px 0 16px 16px;
  width: max-content;

  ${media.min('md')`
    display: none;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  ${media.min('md')`
    display: none;
  `}
`;

/**
 * Export `PayspaceAuthenticationLayout` component.
 */

export function PayspaceAuthenticationLayout(props: Props): JSX.Element {
  const { children, image, logoLink, title } = props;

  return (
    <>
      <StyledLink
        href={logoLink}
        rel={'noopener'}
        target={'_blank'}
      >
        <Icon
          aria-hidden
          color={color('primary')}
          icon={logo}
          size={'100px'}
        />
      </StyledLink>

      <ContentWrapper>
        <Content>
          <StyledImage {...image} />

          <Heading
            as={'h1'}
            color={color('primary')}
            fontWeight={600}
            marginTop={'32px'}
            marginTopMd={0}
          >
            {title}
          </Heading>

          {children}
        </Content>
      </ContentWrapper>
    </>
  );
}
