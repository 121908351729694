
/**
 * Module dependencies.
 */

import { App } from 'client/containers/app';
import {
  DefaultErrorMessage
} from 'client/components/core/error-boundaries/default-error-message';

import { ErrorBoundary } from '@slyk/error-boundaries';
import { Favicons } from 'client/components/core/favicons/favicons';
import {
  GlobalStyle,
  ImageSizeResolverProvider,
  SlykMask,
  SnackbarProvider
} from '@slyk/design-system';

import { LocaleSyncer } from '@slyk/i18n';
import { PayspaceProvider } from '@slyk/payspace-context';
import { ScrollRestoration } from '@slyk/routing';
import { ThemeProvider } from 'styled-components';
import { getImageSources } from 'client/core/utils/image';
import { theme } from 'client/styles/theme';
import React from 'react';
import config from 'config';

/**
 * Domain.
 */

const domain = config.get<string>('app.domain');

/**
 * Early access link.
 */

const earlyAccessLink = config.get<string>('externalLinks.earlyAccess');

/**
 * Slyk link.
 */

const slykLink = 'http://slyk.io';

/**
 * Support email.
 */

const supportEmail = config.get<string>('supportEmail');

/**
 * Export `Root` container.
 */

export function Root(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <ImageSizeResolverProvider>
        <ErrorBoundary errorComponent={DefaultErrorMessage}>
          <GlobalStyle />

          <Favicons />

          <SlykMask />

          <ScrollRestoration />

          <ErrorBoundary errorComponent={DefaultErrorMessage}>
            <LocaleSyncer
              domain={domain}
              languages={config.get('localization.whitelist')}
            />

            <SnackbarProvider>
              <PayspaceProvider
                backgroundImage={getImageSources('payspace-not-found', 'webp')}
                backgroundOverlay={getImageSources('background', 'webp')}
                earlyAccessLink={earlyAccessLink}
                imageMobile={getImageSources('payspace-not-found-mobile', 'webp')}
                logoLink={slykLink}
                supportEmail={supportEmail}
              >
                <App />
              </PayspaceProvider>
            </SnackbarProvider>
          </ErrorBoundary>
        </ErrorBoundary>
      </ImageSizeResolverProvider>
    </ThemeProvider>
  );
}
