
/**
 * Module dependencies.
 */

import { Banner } from 'client/components/home/banner';
import { Connect } from 'client/components/home/connect';
import { Container } from 'client/components/home/container';
import { EarnRewards } from 'client/components/home/earn-rewards/earn-rewards';
import { Footer } from 'client/components/home/footer';
import { GetAppModal } from 'client/components/home/get-app-modal';
import { Header } from 'client/components/home/header/header';
import { Heading, Loading, ModalPortal } from '@slyk/design-system';
import { Helmet } from 'react-helmet';
import { Highlights } from 'client/components/home/highlights';
import { JoinSection } from 'client/components/home/join-section/join-section';
import { Navbar } from 'client/components/home/navbar';
import { TaskList } from 'client/components/home/earn-rewards/task-list';
import { dashboardUrlResolver, getAppDownloadUrl } from 'client/core/utils/url-resolver';
import { find, isEmpty, isNil, map, omitBy, reduce } from 'lodash';
import { formatCurrency } from 'client/core/utils/formatter';
import { gql, useQuery } from '@apollo/client';
import { ifNotProp, prop, theme } from 'styled-tools';
import { media } from 'react-components/styles';
import { payspaceSignupModes } from '@slyk/types';
import { sortedSocialNetworks } from '@slyk/social-networks';
import { useBrowser } from '@slyk/browser';
import { usePayspace } from '@slyk/payspace-context';
import { useQueryString } from 'client/hooks/use-query-string';
import { useTranslate } from '@slyk/i18n';
import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import backgroundImage from 'client/assets/images/home/loading-background.webp';
import cookies from 'browser-cookies';
import routes from '@slyk/config/dashboard-routes';
import styled, { css } from 'styled-components';

/**
 * Social networks.
 */

const socialNetworks = [
  ...sortedSocialNetworks,
  'supportEmail'
];

/**
 * Home query.
 */

const homeQuery = gql`
  query Home {
    defaultCurrency {
      assetCode: code
      decimalPlaces
      decimalPlacesToDisplay
      skipTrailingZeros
      symbol
    }

    fundRaiseProduct: products(
      filter: [{
        name: "typeCode",
        value: "fund_raise"
      }]
      page: {
        number: 1,
        size: 1
      }
      sort: "order"
    ) {
      data {
        assetCode
        description
        endedAt
        gallery {
          image {
            url
          }
        }
        goal
        id
        name
        raised
        typeCode
      }
    }

    socialNetworks: settings(
      filter: [{
        name: "code",
        value: "in:${socialNetworks.join()}"
      }]
    ) {
      data {
        code
        value
      }
    }

    includeTaxOnProductPrice: settingByCode(
      code: "includeTaxOnProductPrice"
    ) {
      value
    }

    products(
      filter: [{
        name: "featured",
        value: "true"
      }]
      page: {
        number: 1,
        size: 4
      }
      sort: "order"
    ) {
      data {
        id
        name
        location
        price
        priceWithTax
        taxRateId
        thumbnail {
          ... on Image {
            status
            type
            url
          }
          ... on Video {
            status
            thumbnailUrl
            type
            url
          }
        }
        unitOfAccount
      }
      total
    }

    referralProgram: referralProgramSettings {
      data {
        code
        value
      }
    }

    rewardAsset: defaultBonusAsset {
      assetCode: code
      baseRates {
        quoteAssetCode
        rate
      }
      decimalPlaces
      decimalPlacesToDisplay
      skipTrailingZeros
      symbol
      type
    }

    tasks(
      all: true
      filter: [{
        name: "enabled",
        value: "true"
      }]
      sort: "order"
    ) {
      data {
        amount
        id
        media {
          ... on Image {
            status
            type
            uploadId
            url
          }
          ... on Video {
            duration
            playbackId
            status
            thumbnailUrl
            type
            uploadId
            url
          }
        }
        name
      }
    }
  }
`;

/**
 * `LoadingContainer` styled component.
 */

const LoadingContainer = styled(Container)`
  display: grid;
  grid-template-rows: 1fr;
  left: 50%;
  padding-top: ${prop('navbarHeight', 122)}px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform ${theme('animations.defaultTransition')};
`;

/**
 * `SectionWrapper` styled component.
 */

const SectionWrapper = styled.div<{ isVisible: boolean }>`
  ${ifNotProp('isVisible', css`
    display: none;
  `)}
`;

/**
 * `Image` styled component.
 */

const Image = styled.img`
  grid-column: 1 / -1;
  grid-row: 1;
  height: auto;
  object-fit: cover;
  width: 100%;
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading)`
  grid-column: 1 / -1;
  grid-row: 1;
  place-self: center;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled(Container).attrs({ as: 'section' })`
  display: grid;
  gap: 32px 16px;
  grid-template-columns: repeat(6, 1fr);

  &::after,
  &::before {
    display: none;
  }

  ${media.min('xs')`
    column-gap: 32px;
    grid-template-columns: repeat(8, 1fr);
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(12, 1fr);
    row-gap: 56px;
  `}

  ${media.min('xl')`
    padding: 0;
  `}
`;

/**
 * `TitleWrapper` styled component.
 */

const TitleWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;

  ${media.min('xs')`
    grid-column: 2 / -2;
  `}

  ${media.min('lg')`
    grid-column: 1 / -1;
  `}
`;

/**
 * `CardsWrapper` styled component.
 */

const CardsWrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-column: 1 / -1;
  grid-row: 2;

  ${media.min('xs')`
    grid-column: 2 / -2;
  `}

  ${media.min('lg')`
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-column: 1 / -1;
  `}
`;

/**
 * Is facebook.
 */

function isFacebook(userAgent: string) {
  return userAgent.includes('FB_IAB/FB4A') || userAgent.includes('FBAN/FBIOS');
}

/**
 * Get setting value.
 */

function getSettingValue(settings: Array<any>, code: string): boolean {
  return find(settings, { code })?.value;
}

/**
 * Export `Home` container.
 */

export function Home(): JSX.Element {
  const [isBannerVisible, setBannerVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [navbarHeight, setNavbarHeight] = useState<number>(0);
  const { i18n, translate } = useTranslate();
  const { name, signupMode, slug } = usePayspace();
  const browser = useBrowser();
  const closeModal = useCallback(() => setModalOpen(false), []);
  const { code } = useQueryString() ?? {};
  const downloadAppUrl = getAppDownloadUrl(slug, code as string | null | undefined);
  const canDownload = browser.some(['mobile', 'tablet']) && !isFacebook(browser.getUA());
  const isPublicPayspace = signupMode === payspaceSignupModes.public;
  const getSlykApp = useCallback(() => {
    if (!canDownload) {
      setModalOpen(true);

      return;
    }

    window.open(downloadAppUrl, '_blank');
  }, [downloadAppUrl, canDownload]);

  const { data, loading: isLoading } = useQuery(homeQuery, {
    context: {
      headers: {
        'x-payspace-slug': slug
      }
    },
    skip: !isPublicPayspace
  });

  const project = useMemo(() => {
    const product = data?.fundRaiseProduct?.data[0];

    if (!product) {
      return;
    }

    return {
      backgroundImage: product?.gallery[0]?.image?.url,
      defaultCurrency: data?.defaultCurrency ? {
        decimalPlacesToDisplay: 0,
        ...data?.defaultCurrency
      } : {},
      endedAt: product?.endedAt ? new Date(product.endedAt) : undefined,
      funded: product?.raised ?? 0,
      goal: product?.goal ?? 0,
      lead: product?.description ?? '',
      title: product?.name ?? ''
    };
  }, [data]);

  const { products, tasks } = useMemo(() => {
    const locale = i18n.language;
    const tasks = map(data?.tasks?.data, ({ amount, ...rest }) => ({
      ...rest,
      amount,
      normalizedAmount: formatCurrency(amount, { ...data?.rewardAsset, locale })
    }));

    const products = map(data?.products?.data, ({
      price,
      priceWithTax,
      taxRateId,
      ...rest
    }) => {
      const defaultCurrency = data?.defaultCurrency;
      const hasTaxIncluded = taxRateId && data?.includeTaxOnProductPrice?.value;
      const priceAmount = new BigNumber(hasTaxIncluded && priceWithTax ? priceWithTax : price);
      const displayValue = formatCurrency(priceAmount, { ...defaultCurrency, locale });
      const isFree = new BigNumber(priceAmount).isZero();
      const rate = data?.rewardAsset?.baseRates?.find((rate: any) => rate?.quoteAssetCode === defaultCurrency?.assetCode);
      const hasPriceConverted = rate?.rate && price && priceAmount.isGreaterThan(0);
      const displayPriceConverted = hasPriceConverted ? formatCurrency(priceAmount.dividedBy(rate.rate), { ...data?.rewardAsset, locale }) : null;

      return {
        ...rest,
        displayPrice: isFree ? translate('products.free') : displayValue,
        displayPriceConverted,
        price
      };
    });

    return {
      products,
      tasks
    };
  }, [data, i18n.language, translate]);

  const [initialTask, ...taskList] = tasks;
  const normalizeReferralProgram = useMemo(() => {
    const settings = data?.referralProgram?.data;

    return omitBy({
      referralEarnEnabled: getSettingValue(settings, 'referralEarnBonusEnabled'),
      referralEarnPercentage: getSettingValue(settings, 'referralEarnBonusPercentage'),
      referralEnabled: getSettingValue(settings, 'referralPurchaseBonusEnabled'),
      referralLimit: getSettingValue(settings, 'referralPurchaseBonusLimit'),
      referralNetworkEnabled: getSettingValue(settings, 'referralNetworkPurchaseBonusEnabled'),
      referralPercentage: getSettingValue(settings, 'referralPurchaseBonusPercentage')
    }, isNil);
  }, [data]);

  const hasEarnRewards = useMemo(() => {
    return data?.rewardAsset && normalizeReferralProgram.referralEnabled;
  }, [data, normalizeReferralProgram.referralEnabled]);

  const socialNetworks = useMemo(() => {
    return reduce(data?.socialNetworks?.data, (result, { code, value }) => {
      if (isNil(value)) {
        return result;
      }

      if (code === 'supportEmail') {
        return {
          ...result,
          email: value
        };
      }

      return {
        ...result,
        [code]: value
      };
    }, {});
  }, [data]);

  const handleDismiss = useCallback(() => {
    setBannerVisible(false);
  }, []);

  useEffect(() => {
    if (isNil(cookies.get('sessionToken'))) {
      return setBannerVisible(false);
    }

    return setBannerVisible(true);
  }, []);

  useEffect(() => {
    const elem = document.getElementsByTagName('nav')[0];

    if (elem) {
      setNavbarHeight(elem.clientHeight);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      const elem = document.getElementsByTagName('nav')[0];

      if (elem) {
        setNavbarHeight(elem.clientHeight);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Helmet titleTemplate={'%s'}>
        <title>
          {translate('title', { name })}
        </title>
      </Helmet>

      {isBannerVisible && (
        <Banner
          goToDashboard={dashboardUrlResolver(slug, routes.overview)}
          onDismiss={handleDismiss}
        />
      )}

      <Navbar
        downloadAppDisabled={isLoading}
        onGetApp={getSlykApp}
      />

      {!!isLoading && (
        <LoadingContainer navbarHeight={navbarHeight}>
          <Image
            alt={'Loading background'}
            aria-hidden
            src={backgroundImage}
          />

          <StyledLoading
            active
            relative
          />
        </LoadingContainer>
      )}

      <SectionWrapper isVisible={!isLoading}>
        {!!project && (
          <Header
            {...project}
            navbarHeight={navbarHeight}
            onClickInvest={getSlykApp}
            projectImage={project?.backgroundImage}
          />
        )}

        <JoinSection
          canDownload={canDownload}
          code={code as string | null | undefined}
          downloadAppUrl={downloadAppUrl}
          isHeader={!project}
          navbarHeight={navbarHeight}
          onGetApp={getSlykApp}
          products={products}
        />

        {signupMode === payspaceSignupModes.public && (
          <>
            {(!isEmpty(products) || hasEarnRewards) && (
              <ContentWrapper>
                <TitleWrapper>
                  <Heading
                    as={'h2'}
                    fontWeight={600}
                    level={'h3'}
                  >
                    {translate('earnRewards.title')}
                  </Heading>
                </TitleWrapper>

                <CardsWrapper>
                  {hasEarnRewards && (
                    <EarnRewards
                      cardDirection={isEmpty(products) ? 'row' : 'column'}
                      name={name}
                      onGoToGetAccess={getSlykApp}
                      referralProgram={normalizeReferralProgram}
                      rewardAsset={data.rewardAsset}
                      task={initialTask}
                    />
                  )}

                  {!isEmpty(products) && (
                    <Highlights
                      hasEarnRewards={hasEarnRewards}
                      onGoToGetAccess={getSlykApp}
                      products={products}
                    />
                  )}
                </CardsWrapper>
              </ContentWrapper>
            )}

            {taskList.length !== 0 && (
              <TaskList
                name={name}
                onGoToGetAccess={getSlykApp}
                referralEnabled={normalizeReferralProgram.referralEnabled}
                rewardAsset={data.rewardAsset}
                tasks={taskList}
              />
            )}

            {!isEmpty(socialNetworks) && (
              <Connect socialNetworks={socialNetworks} />
            )}
          </>
        )}

        <Footer
          getSlykApp={getSlykApp}
          payspaceName={name}
          payspaceSlug={slug}
        />
      </SectionWrapper>

      {!canDownload && (
        <ModalPortal isOpen={isModalOpen}>
          <GetAppModal
            code={code as string | null | undefined}
            downloadAppUrl={downloadAppUrl}
            isVisible={isModalOpen}
            onRequestClose={closeModal}
          />
        </ModalPortal>
      )}
    </>
  );
}
