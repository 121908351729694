
/**
 * Module dependencies.
 */

import { Button, Loading, Text } from '@slyk/design-system';
import { Helmet } from 'react-helmet';
import {
  PayspaceAuthenticationLayout
} from 'client/components/layouts/payspace-authentication-layout';

import { Redirect } from 'react-router-dom';
import { color } from 'react-components/styles';
import { getImageSources } from 'client/core/utils/image';
import { gql, useMutation } from '@apollo/client';
import { usePayspace } from '@slyk/payspace-context';
import { useQueryString } from 'client/hooks/use-query-string';
import { useTranslate } from '@slyk/i18n';
import React, { useEffect, useState } from 'react';
import config from 'config';
import payspaceRoutes from '@slyk/config/payspace-routes';
import slykEndpoints from '@slyk/config/slyk-endpoints';
import styled from 'styled-components';

/**
 * Slyk link.
 */

const slykLink = config.get<string>('externalLinks.slykWebsite');

/**
 * Confirm email mutation.
 */

const confirmEmailMutation = gql`
  mutation ConfirmEmail($domain: String!, $token: String!) {
    confirmEmail(
      input: {
        domain: $domain
        token: $token
      }
    ) @rest(
      method: "POST"
      path: "${slykEndpoints.confirmEmail}"
    ) {
      NoResponse
    }
  }
`;

/**
 * `LoadingWrapper` styled component.
 */

const LoadingWrapper = styled.div`
  color: ${color('primary')};
  margin-top: 40px;
  text-align: center;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  margin-top: 32px;
`;

/**
 * Export `ConfirmEmail` container.
 */

export function ConfirmEmail(): JSX.Element {
  const { translate } = useTranslate();
  const { token } = useQueryString();
  const payspace = usePayspace();
  const [status, setStatus] = useState('isLoading');
  const [confirmEmail] = useMutation(confirmEmailMutation, {
    onCompleted: () => setStatus('isSuccessful'),
    onError: () => setStatus('isFailed'),
    variables: {
      domain: payspace.slug,
      token
    }
  });

  useEffect(() => {
    if (!token) {
      return;
    }

    confirmEmail();
  }, [confirmEmail, token]);

  if (!token) {
    return <Redirect to={payspaceRoutes.home} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('confirmEmail.metaTitle')}
        </title>
      </Helmet>

      <PayspaceAuthenticationLayout
        image={getImageSources('confirm-email-mobile', 'webp')}
        logoLink={slykLink}
        title={translate(`confirmEmail.${status}.title`)}
      >
        {status !== 'isLoading' && (
          <Text marginTop={'24px'}>
            {translate(`confirmEmail.${status}.lead`)}
          </Text>
        )}

        {status !== 'isFailed' && (
          <Text marginTop={'24px'}>
            {translate(`confirmEmail.${status}.description`)}
          </Text>
        )}

        {status === 'isLoading' && (
          <LoadingWrapper>
            <Loading
              active
              relative
              size={24}
              type={'circles'}
            />
          </LoadingWrapper>
        )}

        {status === 'isSuccessful' && (
          <StyledButton
            size={'large'}
            to={payspaceRoutes.home}
          >
            {translate('confirmEmail.isSuccessful.continue')}
          </StyledButton>
        )}
      </PayspaceAuthenticationLayout>
    </>
  );
}
