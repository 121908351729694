
/**
 * Module dependencies.
 */

import { Helmet } from 'react-helmet';
import { MailTo } from 'client/components/core/mail-to';
import {
  PayspaceAuthenticationLayout
} from 'client/components/layouts/payspace-authentication-layout';

import { Text } from '@slyk/design-system';
import { Trans } from 'react-i18next';
import { color } from 'react-components/styles';
import { getImageSources } from 'client/core/utils/image';
import { useTranslate } from '@slyk/i18n';
import React from 'react';
import config from 'config';

/**
 * Slyk link.
 */

const slykLink = config.get<string>('externalLinks.slykWebsite');

/**
 * Support email.
 */

const supportEmail = config.get<string>('supportEmail');

/**
 * Export `PayspaceNotActive` container.
 */

export function PayspaceNotActive(): JSX.Element {
  const { translate } = useTranslate();

  return (
    <>
      <Helmet>
        <title>
          {translate('notActive.metaTitle')}
        </title>
      </Helmet>

      <PayspaceAuthenticationLayout
        image={getImageSources('payspace-not-active-mobile', 'webp')}
        logoLink={slykLink}
        title={translate('notActive.title')}
      >
        <Text
          marginTop={'24px'}
          marginTopMd={'40px'}
          size={'large'}
        >
          {translate('notActive.description')}
        </Text>

        {supportEmail && (
          <Text
            color={color('gray500')}
            paddingTop={'8px'}
            size={'small'}
          >
            <Trans
              components={[(
                <MailTo
                  email={supportEmail}
                  key={0}
                />
              )]}
              i18nKey={'notActive.contactSupport'}
            />
          </Text>
        )}
      </PayspaceAuthenticationLayout>
    </>
  );
}
