
/**
 * Module dependencies.
 */

import {
  Form,
  Heading,
  PasswordInput,
  SubmitButton,
  Text,
  useSnackbar
} from '@slyk/design-system';

import { Helmet } from 'react-helmet';
import { MainContent } from 'client/components/layouts/page-layout';
import { Redirect, useHistory } from 'react-router-dom';
import { color } from 'react-components/styles';
import { getNetworkErrorTranslationPaths } from '@slyk/core';
import { gql, useMutation } from '@apollo/client';
import { password } from '@slyk/regex';
import { usePayspace } from '@slyk/payspace-context';
import { useQueryString } from 'client/hooks/use-query-string';
import { useTranslate } from '@slyk/i18n';
import React, { useCallback } from 'react';
import routes from '@slyk/config/payspace-routes';
import slykEndpoints from '@slyk/config/slyk-endpoints';
import styled from 'styled-components';

/**
 * Reset password mutation.
 */

const resetPasswordMutation = gql`
  mutation ResetPassword(
    $password: String!
    $payspaceSlug: String!
    $token: String!
  ) {
    resetPassword(
      input: {
        domain: $payspaceSlug
        password: $password
        token: $token
      }
    ) @rest(
      method: "POST"
      path: "${slykEndpoints.resetPassword}"
    ) {
      NoResponse
    }
  }
`;

/**
 * Json schema.
 */

const jsonSchema = {
  additionalProperties: false,
  properties: {
    password: {
      pattern: password,
      type: 'string'
    }
  },
  required: ['password'],
  type: 'object'
};

/**
 * `Content` styled component.
 */

const Content = styled.div` 
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

/**
 * Export `ResetPassword` container.
 */

export function ResetPassword(): JSX.Element {
  const { translate } = useTranslate();
  const { showErrorMessage } = useSnackbar();
  const { token } = useQueryString();
  const history = useHistory();
  const payspace = usePayspace();
  const title = translate('resetPassword.title');
  const [resetPassword] = useMutation(resetPasswordMutation, {
    onCompleted: () => {
      history.push(routes.resetPasswordSuccess);
    },
    onError: (error: any) => {
      showErrorMessage(translate(getNetworkErrorTranslationPaths(
        error?.networkError?.result?.code,
        'resetPassword.errors'
      )));
    }
  });

  const handleSubmit = useCallback(({ password }: { password: string }) => {
    return resetPassword({
      variables: {
        password,
        payspaceSlug: payspace.slug,
        token
      }
    });
  }, [payspace.slug, resetPassword, token]);

  if (!token) {
    return <Redirect to={routes.home} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>

      <MainContent>
        <Content>
          <Heading
            as={'h1'}
            color={color('primary')}
            fontWeight={600}
          >
            {title}
          </Heading>

          <Text
            color={color('gray500')}
            marginBottom={'24px'}
            marginBottomMd={'40px'}
          >
            {translate('resetPassword.description')}
          </Text>

          <Form
            jsonSchema={jsonSchema}
            onSubmit={handleSubmit}
          >
            <PasswordInput
              autoFocus
              hasRequirements
              label={translate('resetPassword.form.password')}
              name={'password'}
            />

            <SubmitButton
              fullWidth
              size={'large'}
            >
              {translate('resetPassword.form.submit')}
            </SubmitButton>
          </Form>
        </Content>
      </MainContent>
    </>
  );
}
