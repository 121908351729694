
/**
 * Module dependencies.
 */

import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import qs, { ParsedQs } from 'qs';

/**
 * Export `useQueryString` hook.
 */

export function useQueryString(): ParsedQs {
  const { search } = useLocation();
  const query = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  return query;
}
