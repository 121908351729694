
/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { Link, logo } from '@slyk/design-system';
import { color, media } from 'react-components/styles';
import { useBreakpoint } from '@slyk/breakpoint';
import { useBrowser } from '@slyk/browser';
import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  header: ReactNode;
  logoLink: string;
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  ${media.min('md')`
    column-gap: 32px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  `}
`;

/**
 * `Header` styled component.
 */

const Header = styled.header`
  grid-column: 1 / span 6;
  position: relative;
`;

/**
 * `IconWrapper` styled component.
 */

const IconWrapper = styled.div`
  left: 24px;
  position: absolute;
  top: 32px;
`;

/**
 * `Main` styled component.
 */

const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;

  ${media.min('md')`
    grid-column: span 6 / -1;
    padding-right: 32px;
  `}
`;

/**
 * Export `MainContent` styled component.
 */

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 448px;
  padding: 16px 16px 0;
  width: 100%;

  ${media.min('xs')`
    padding: 16px 0 0;
  `}

  ${media.min('md')`
    padding: 32px 0 0;
  `}
`;

/**
 * Export `PageLayout` component.
 */

export function PageLayout(props: Props): JSX.Element {
  const { children, header, logoLink } = props;
  const isDesktop = useBreakpoint('ms', 'min');
  const browser = useBrowser();
  const isMobilePlatform = browser.getPlatformType() === 'mobile';

  useEffect(() => {
    if (!isMobilePlatform) {
      return;
    }

    if (document.documentElement) {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }, [isMobilePlatform]);

  return (
    <Section>
      {isDesktop && (
        <Header>
          {header}

          <IconWrapper>
            <Link
              href={logoLink}
              rel={'noopener'}
              target={'_blank'}
            >
              <Icon
                aria-hidden
                color={color('white')}
                icon={logo}
                size={'100px'}
              />
            </Link>
          </IconWrapper>
        </Header>
      )}

      <Main>
        {children}
      </Main>
    </Section>
  );
}
