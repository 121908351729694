
/**
 * Module dependencies.
 */

import { Link } from '@slyk/design-system';
import React, { ReactNode } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
  className?: string;
  colorTheme?: string;
  email: string;
  hasRel?: boolean;
  openInNewTab?: boolean;
  rel?: string;
};

/**
 * Export `MailTo` component.
 */

export function MailTo(props: Props): JSX.Element {
  const {
    children,
    email,
    hasRel = true,
    openInNewTab = true,
    rel = 'noopener',
    ...rest
  } = props;

  return (
    <Link
      {...rest}
      {...hasRel ? { rel } : {}}
      href={`mailto:${email}`}
      target={openInNewTab ? '_blank' : '_self'}
    >
      {children}
    </Link>
  );
}
