
/**
 * Module dependencies.
 */

import { Box } from 'react-components/layout';
import {
  Button,
  Divider,
  Heading,
  Link,
  Text,
  logo,
  typography
} from '@slyk/design-system';

import { Container } from 'client/components/home/container';
import { Icon } from 'react-components/media';
import { Trans } from 'react-i18next';
import { color, media } from 'react-components/styles';
import { dashboardUrlResolver } from 'client/core/utils/url-resolver';
import { getImageSources } from 'client/core/utils/image';
import { useTranslate } from '@slyk/i18n';
import RawHtml from 'react-components/raw-html';
import React from 'react';
import config from 'config';
import routes from '@slyk/config/dashboard-routes';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  getSlykApp: () => void;
  payspaceName: string;
  payspaceSlug: string;
};

/**
 * Links configuration constant.
 */

const linksConfig = [{
  label: 'payspaceTermsOfService',
  url: config.get('externalLinks.userTerms')
}, {
  label: 'endUserTermsOfService',
  url: config.get('externalLinks.payspaceTerms')
}, {
  label: 'privacyPolicy',
  url: config.get('externalLinks.privacy')
}];

/**
 * `FooterWrapper` styled component.
 */

const FooterWrapper = styled.footer`
  background-color: ${color('secondary')};
  color: ${color('white')};
  position: relative;
`;

/**
 * `FooterImage` styled component.
 */

const FooterImage = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

/**
 * `StyledDivider` styled component.
 */

const StyledDivider = styled(Divider)`
  background-color: ${color.transparentize('white', 0.1)};
  position: relative;
`;

/**
 * `Name` styled component.
 */

const Name = styled(Heading)`
  margin-bottom: 32px;
  padding-top: 80px;
  text-align: center;

  ${media.min('ms')`
    margin-bottom: 45px;
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  width: 100%;

  ${media.min('xs')`
    margin: 0 auto;
    width: max-content;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  column-gap: 32px;
  display: grid;
  grid-template-areas: 'title' 'lead' 'links' 'owner' 'author';
  grid-template-columns: 1fr;
  padding: 45px 0 78px;
  text-align: left;

  ${media.min('ms')`
    grid-template-areas:
      'title .'
      'lead links'
      '. .'
      'author owner';
    grid-template-columns: 1fr 1fr;
    padding: 52px 0 34px;
  `}

  ${media.min('xl')`
    margin: 0 -32px;
  `}
`;

/**
 * `List` styled component.
 */

const List = styled.ul`
  grid-area: links;
  margin-bottom: 24px;

  ${media.min('ms')`
    text-align: right;
  `}
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  ${typography.styles.textNormal}

  color: ${color('white')};
`;

/**
 * Export `Footer` component.
 */

export function Footer({ getSlykApp, payspaceName, payspaceSlug }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <FooterWrapper>
      <FooterImage
        alt={''}
        height={'100%'}
        loading={'lazy'}
        src={getImageSources('home/footer-bg', 'webp')?.retinaUrl}
        width={'100%'}
      />

      <Container>
        <Box
          marginBottom={'56px'}
          marginBottomXl={'70px'}
          marginLeft={'auto'}
          marginRight={'auto'}
          maxWidth={'580px'}
        >
          <RawHtml element={Name}>
            {translate('footer.appDownload.title', { name: payspaceName })}
          </RawHtml>

          <StyledButton
            onClick={getSlykApp}
            size={'large'}
          >
            {translate('footer.appDownload.action')}
          </StyledButton>
        </Box>
      </Container>

      <Box as={'footer'}>
        <StyledDivider />

        <Container>
          <Grid>
            <Heading
              as={'p'}
              gridArea={'title'}
              level={'h4'}
              marginBottom={'4px'}
              marginBottomMs={'16px'}
            >
              {translate('footer.title')}
            </Heading>

            <Text
              gridArea={'lead'}
              marginBottom={'48px'}
              marginBottomMs={'70px'}
            >
              {translate('footer.lead')}
            </Text>

            <Text gridArea={'author'}>
              {translate('footer.author')}

              <StyledLink
                aria-label={'Slyk website'}
                href={config.get('externalLinks.slykWebsite')}
                rel={'noopener'}
                style={{ marginLeft: 6 }}
                target={'_blank'}
              >
                <Icon
                  aria-hidden
                  icon={logo}
                  size={'56px'}
                />
              </StyledLink>
            </Text>

            <List>
              {linksConfig.map(({ label, url }) => (
                <li key={label}>
                  <StyledLink
                    href={url}
                    rel={'noopener'}
                    target={'_blank'}
                  >
                    {translate(`footer.links.${label}`)}
                  </StyledLink>
                </li>
              ))}
            </List>

            <Text
              gridArea={'owner'}
              marginBottom={'48px'}
              marginBottomMs={0}
              textAlignMs={'right'}
            >
              <Trans
                components={[
                  <StyledLink
                    href={dashboardUrlResolver(payspaceSlug, routes.signIn)}
                    key={0}
                  />
                ]}
                i18nKey={'footer.owner'}
              />
            </Text>
          </Grid>
        </Container>
      </Box>
    </FooterWrapper>
  );
}
